.component {
  width: 100%;
  padding: 0 5px;
  margin: 0 auto 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 5px;
  position: absolute;
}
@media screen and (orientation: landscape) {
  .component {
    bottom: 1.5em;
  }
}

.iconContainerMobile {
  display: none;
  flex-direction: column;
  bottom: 40px;
  right: 2px;
  position: absolute;
}

.iconContainer {
  display: inline;
  flex-shrink: 0;
}

.iconContainerDisplay {
  display: inline;
}

.mobile-setting {
  display: none;
}

.btn-disable {
  cursor: not-allowed;
  pointer-events: none;
  /*Button disabled - CSS color class*/
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

#fullscreen_button {
  min-width: 26px;
}

@media screen and (max-width: 600px) and (orientation: portrait) {
  .mobile-setting {
    display: inline;
  }

  .iconContainer {
    background-color: rgba(20, 20, 20, 0.8);
    border-radius: 5px;
    flex-direction: column;
    bottom: 40px;
    right: 2px;
    align-items: center;
    height: 105px;
    width: 80px;
    position: absolute;
    flex-wrap: wrap;
  }
  .iconContainer svg.icon {
    height: 2.1em !important;
    width: 2.1em !important;
    overflow: visible;
  }

  .iconContainerDisplay {
    display: none;
  }
}
@media (hover: hover) and (pointer: fine) {
  .component:hover {
    /* display:block; */
    opacity: 1;
  }
}
.inner {
  /* width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; */
  width: 100%;
  /* max-width: 800px; */
  /*   height: 35px; */
  margin: 0;
  padding: 0 7px 0 7px;
  background-color: rgba(20, 20, 20, 0.8);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* opacity: 0; */
  transition: opacity 0.3s;
}

.icon {
  padding: 5px;
}

.show-icon {
  display: block;
}

.hide-icon {
  display: none;
}

.duration {
  padding: 5px;
  height: 35;
  width: 35;
  color: white;
}

.menu {
  position: absolute;
  z-index: 2;
}
[type=range]::-ms-tooltip {
  display: none;
}

[type=range].volume-bar {
  -webkit-writing-mode: bt-lr;
      -ms-writing-mode: bt-lr;
          writing-mode: bt-lr;
  /* IE */
  -webkit-appearance: slider-vertical;
  /* WebKit */
  width: 30px;
  padding: 0 5px;
  margin-top: 5px;
  z-index: 100;
}
[type=range].seek-bar {
  -webkit-appearance: none;
  margin: 12px 0;
  width: 100%;
  background: transparent;
}
[type=range].seek-bar::-moz-focus-outer {
  border: 0;
}
[type=range].seek-bar:focus {
  outline: 0;
}
[type=range].seek-bar::-webkit-slider-runnable-track {
  cursor: default;
  height: 8px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 100%;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2), 0 0 1px rgba(13, 13, 13, 0.2);
  border: 2px solid #cfd8dc;
  border-radius: 5px;
}
[type=range].seek-bar::-webkit-slider-thumb {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2), 0 0 4px rgba(13, 13, 13, 0.2);
  background: #607d8b;
  border: 2px solid #eceff1;
  border-radius: 12px;
  box-sizing: border-box;
  cursor: default;
  height: 24px;
  width: 24px;
  -webkit-appearance: none;
  margin-top: -10px;
}
[type=range].seek-bar::-moz-range-track {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2), 0 0 1px rgba(13, 13, 13, 0.2);
  cursor: default;
  height: 8px;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 100%;
  border: 2px solid #cfd8dc;
  border-radius: 5px;
  height: 4px;
}
[type=range].seek-bar::-moz-range-thumb {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2), 0 0 4px rgba(13, 13, 13, 0.2);
  background: #607d8b;
  border: 2px solid #eceff1;
  border-radius: 12px;
  box-sizing: border-box;
  cursor: default;
  height: 24px;
  width: 24px;
}
[type=range].seek-bar::-ms-track {
  cursor: default;
  height: 8px;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 100%;
  background: transparent;
  border-color: transparent;
  border-width: 12px 0;
  color: transparent;
}
[type=range].seek-bar::-ms-fill-lower {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2), 0 0 1px rgba(13, 13, 13, 0.2);
  background: white;
  border: 2px solid #cfd8dc;
  border-radius: 10px;
}
[type=range].seek-bar::-ms-fill-upper {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2), 0 0 1px rgba(13, 13, 13, 0.2);
  border: 2px solid #cfd8dc;
  border-radius: 10px;
}
[type=range].seek-bar::-ms-thumb {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2), 0 0 4px rgba(13, 13, 13, 0.2);
  background: #607d8b;
  border: 2px solid #eceff1;
  border-radius: 12px;
  box-sizing: border-box;
  cursor: default;
  height: 24px;
  width: 24px;
  margin-top: 2px;
}
[type=range].seek-bar:disabled::-webkit-slider-thumb, [type=range].seek-bar:disabled::-moz-range-thumb, [type=range].seek-bar:disabled::-ms-thumb, [type=range].seek-bar:disabled::-webkit-slider-runnable-track, [type=range].seek-bar:disabled::-ms-fill-lower, [type=range].seek-bar:disabled::-ms-fill-upper {
  cursor: not-allowed;
}
@charset "UTF-8";
div.Settings-dialog {
  max-width: 80vw;
  font-size: 1.3vw;
  margin: auto;
  margin-top: 5vw;
  scrollbar-color: auto;
  text-align: left;
  font-family: unset;
}
div.Settings-dialog ::-webkit-scrollbar {
  height: 12px;
  width: 12px;
  background: rgba(51, 51, 51, 0.7);
}
div.Settings-dialog ::-webkit-scrollbar-thumb {
  background: rgba(26, 26, 26, 0.7);
  -webkit-border-radius: 1ex;
}
div.Settings-dialog ::-webkit-scrollbar-corner {
  background: rgba(26, 26, 26, 0.7);
}
div.Settings-dialog .modal-content {
  background: transparent;
  border-color: transparent;
  background: rgba(0, 0, 0, 0.7);
}
div.Settings-dialog .modal-content .modal-header,
div.Settings-dialog .modal-content .modal-footer {
  border-color: transparent;
}
div.Settings-dialog .modal-content .modal-title {
  width: 100%;
}
div.Settings-dialog .modal-content .modal-title span {
  float: right;
  color: white;
}
div.Settings-dialog .modal-content .panels {
  margin: 0 5%;
}
div.Settings-dialog .modal-content .panels span {
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
div.Settings-dialog .modal-content .panels span button {
  flex-grow: 1;
  margin: 0.5em 0;
  margin-left: 5%;
  font-size: 2vw;
}
div.Settings-dialog .modal-content .panels span button:first-child {
  margin-left: 0;
}
div.Settings-dialog .modal-content .panels .main {
  display: inline-flex;
  width: 100%;
  height: 26em;
}
div.Settings-dialog .modal-content .panels .main div.panel {
  max-width: 50%;
  width: 100%;
  display: flex;
  flex-grow: 1;
  border-radius: none;
  border-right: none;
  border-top: none;
  border-left: 2px solid;
  -o-border-image: linear-gradient(to bottom, transparent 10%, #aaa 10% 90%, transparent 90%) 1 100%;
     border-image: linear-gradient(to bottom, transparent 10%, #aaa 10% 90%, transparent 90%) 1 100%;
}
div.Settings-dialog .modal-content .panels .main div.panel:first-child {
  border-left: none;
}
div.Settings-dialog .modal-content .panels .main .main-panel li:last-child {
  color: turquoise;
  font-weight: bold;
  margin-top: 0.5em;
  width: 60%;
}
div.Settings-dialog .modal-content .panels .captions-preview {
  margin-top: 0.2em;
  padding: 2px;
  white-space: nowrap;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: slidein;
          animation-name: slidein;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  display: inline-block;
  position: absolute;
  bottom: -2em;
  width: auto;
}
@-webkit-keyframes slidein {
  from {
    left: 0;
  }
  to {
    left: 30vw;
  }
}
@keyframes slidein {
  from {
    left: 0;
  }
  to {
    left: 30vw;
  }
}
div.Settings-dialog .modal-content .setting {
  display: flex;
  color: #fff;
  flex-direction: column;
  font-size: 110%;
  margin-right: 2em;
  padding: 0 2em;
  width: 100%;
}
div.Settings-dialog .modal-content .setting > span {
  display: block;
  font-weight: bold;
  text-transform: capitalize;
  padding: 0.2em 0.5em;
}
div.Settings-dialog .modal-content .setting ul {
  padding: 0;
  list-style-type: none;
  list-style-position: outside;
  width: 100%;
  overflow-y: auto;
  overflow-x: visible;
}
div.Settings-dialog .modal-content .setting ul li.active {
  background-color: turquoise;
  color: #111;
}
@media screen and (hover) {
  div.Settings-dialog .modal-content .setting ul li:hover {
    border-color: turquoise;
  }
}
div.Settings-dialog .modal-content .setting ul li {
  border: solid transparent 1px;
  border-radius: 0.25rem;
  cursor: pointer;
  line-height: 2;
  width: 100%;
  margin: 0.2em 0;
  padding: 0.2em 0.5em;
  white-space: nowrap;
}
div.Settings-dialog .modal-content .setting ul li div {
  display: inline-flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
div.Settings-dialog .modal-content .setting ul li div span {
  display: inline-block;
  white-space: nowrap;
  flex-grow: 1;
  width: 35%;
}
div.Settings-dialog .modal-content .setting ul li div span:first-child {
  flex-grow: 2;
  width: 60%;
}
div.Settings-dialog .modal-content .setting ul li span.cc {
  display: inline-block;
  border-width: thin;
  border-style: solid;
  border-radius: 0.2em;
  padding: 0.07em 0.15em 0.15em 0.15em;
  margin: 1em 0.5em 0.5em 0.5em;
  font-size: 0.7em;
  height: auto;
  width: auto;
  line-height: 0.7;
  vertical-align: top;
}
div.Settings-dialog .modal-content .setting ul li span.cc::before {
  content: "㏄";
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  div.Settings-dialog {
    font-size: 2vw;
  }
  div.Settings-dialog .modal-content .panels .main {
    display: flex;
    flex-direction: column;
    height: auto;
  }
  div.Settings-dialog .modal-content .panels .main div.panel {
    max-width: 100%;
    display: block;
    border: none;
  }
  div.Settings-dialog .modal-content .panels .main div.panel .setting {
    display: block;
    font-size: 100%;
  }
  div.Settings-dialog .modal-content .panels .main div.panel .setting select {
    margin: auto;
    width: 75%;
    background-color: inherit;
    color: inherit;
  }
  div.Settings-dialog .modal-content .panels .main div.panel .setting button {
    font-size: 100%;
    margin-top: 1em;
  }
}
.giant_buttons {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}

@media (hover: hover) and (pointer: fine) {
  .giant_buttons:hover {
    opacity: 1;
  }
}
.show {
  opacity: 1;
}

.hidden {
  opacity: 0;
}