@import url(//fonts.googleapis.com/css?family=Vibur);

body {
  background: rgba(17, 17, 34, .2) url('./brickwall.jpg')  no-repeat center center fixed; 
  background-size: cover;
  margin: 0;
  overflow-x: hidden;
}

.ticket-check {
    z-index: 1000;
    display: block;
    position: fixed;
    animation-duration: 1s;
    animation-name: slidein;
    left: calc(50% - 15vw);
    background-color: #131;
    width: 30vw;
    margin: auto;

    
    padding: 1em 3em 3em 3em;
    border: solid .2em #eee;
    border-radius: .6em;
    top: 50%;
    transform: translate(0, -50%);
    color: #eee;
    font-family: 'Permanent Marker', cursive;
    p {
      font-size: 120%;
      padding: .1 em;
    }
    input, button {
      margin-top: 1em;
      margin-bottom: 1em;
    
    }

    img {
      width: 100%;
      height: auto;
    }
    p.error {
      color: red;
    }
    &.error input[type=text] {
      background-color: pink;
      color: red;
    }

    input {
        line-height: 2em;
        display: block;
        font-size: 110%;
        padding: 0 1em;
        margin: .3em auto;
    }
    
    button, input[type=submit] {
        display: block;
        line-height: 2em;
        text-transform: capitalize;
        appearance: unset;
        margin: .3em auto;
        padding: 0 1em;
        background-color: orangered;
        color: whitesmoke;
        font-size: 110%;
        border: solid thin whitesmoke;
        border-radius: .2em;
    }
}

@media screen and (max-width: 600px)  {
  .ticket-check {
    left: calc(50% - 33vw);
    width: 40vw;
  }
}


@keyframes slidein {
  from {
    top: 0%;
    transform: translate(0, -100%);
  }

  to {
    top: 50%;
    transform: translate(0, -50%);
  }
}



.slide-out {
  animation-duration: 1s;
  animation-name: slideout;
}

@keyframes slideout {
  from {
    top: 50%;
    transform: translate(0, -50%);
  }
  to {
    top: 0%;
    transform: translate(0, -100%);
  }
}
