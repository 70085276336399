
@function stream_size_unit($factor, $adj) {
    @return calc(#{$factor} * 100vw / (var(--streams-count) + 2) - #{$adj}px)
}

.inconcert {
    --video-width: 16;
    --video-height: 9;

    button.btn-right {
        right: 10px;
        z-index: 999;
        background-color: transparent;
        color: white;
        padding: 0.33em;
        margin: 0.33em;
        text-transform: capitalize;
        border: 1px solid transparent;
        border-radius: 0px;
      }

    --stream-count-gap-no: 2;

    @media screen and (max-width: 600px)  {
        --stream-count-gap-no: 7;
    }

    @media screen and (max-width: 1000px)  {
        --stream-count-gap-no: 5;
    }

    div.inconcert-view {
        --video-aspect-ratio: calc(var(--video-width) / var(--video-height));
        &:not(.vertical) {
            --side-video-width: calc(100vw / (var(--streams-count) + var(--stream-count-gap-no)));
            --side-video-height: calc(100vw / (var(--streams-count) + var(--stream-count-gap-no)) / var(--video-aspect-ratio));
            @media screen and (min-width: 1000px)  {
                --side-video-width: calc(55vw / (var(--streams-count) + var(--stream-count-gap-no)));
                --side-video-height: calc(55vw / (var(--streams-count) + var(--stream-count-gap-no)) / var(--video-aspect-ratio));
            }
        }
        &.vertical {
            --side-video-width: calc(100vh / (var(--streams-count) + var(--stream-count-gap-no)) * var(--video-aspect-ratio));
            --side-video-height: calc(100vh / (var(--streams-count) + var(--stream-count-gap-no)));
        }

        display: flex;
        flex-direction: column;
        width: calc(var(--video-aspect-ratio) * 100vh);
        max-width: 100vw;
        margin: auto;
        position: relative;
        bottom: 0;
        height: 100%;
        // justify-content: space-between;
        align-items: flex-end;
        align-content: flex-end;
        background: transparent;
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-weight: 300;

        .playercontainer {

            .end-fade {
                z-index: 1000;
                display: block;
                position: fixed;
                background-color: #131;
                width: 30vw;
                margin: auto;
            
                
                padding: 1em 3em 3em 3em;
                border: solid .2em #eee;
                border-radius: .6em;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #eee;
                font-family: 'Permanent Marker', cursive;
                p {
                  font-size: 120%;
                  padding: .1 em;
                }
                
                button {
                    display: block;
                    margin-top: 1em;
                    margin-bottom: 1em;
                    line-height: 2em;
                    text-transform: capitalize;
                    appearance: unset;
                    margin: .3em auto;
                    padding: 0 1em;
                    background-color: orangered;
                    color: whitesmoke;
                    font-size: 110%;
                    border: solid thin whitesmoke;
                    border-radius: .2em;
                }
            }
            span.label {
                z-index: 2000;
                display: inline-block;
                color:  #dd0000dd;
                text-transform: uppercase;
                font-family: 'Roboto';
                font-size: 70%;
                position: absolute;
                left: .3em;
                top: .3em;
                user-select: none;
            }
            video {
                object-fit: cover;
                width: 100%;
            }
            .in_captions {
                display: none !important;
            }

            z-index: 3;
            max-width: 100%;
            &:not(.main) {
                width: var(--side-video-width);
                height: var(--side-video-height);
                position: relative !important;    
                margin-top: 1em;
                margin-right: 1em;
                // max-width: calc(20vh / var(--video-aspect-ratio));
                video {
                    overflow: hidden;
                    vertical-align: middle;
                }
                border: solid 1px #ffd700a3;
            }
            &.placeholder {
                width: var(--side-video-width);
                height: var(--side-video-height);
                border: solid 1px #d72200a3;
            }
            display: inline-block;
            &.main {
                position: absolute !important;
                z-index: 2;
                width: 100%;
                left: 0;
                top: 0;
                border: none;
                margin-top: 0;
                display: block;

                video {
                    border: none;
                    transform: rotate(var(--main-video-rotation));
                }
                $wifi-size: 290px;
                &.buffering::after {
                    display: block;
                    position: absolute;
                    top: .33em;
                    right: 3em;
                    padding: 0;
                    width: 1.5em;
                    height: 1.5em;
                    animation: blink 1.5s linear infinite;
                }

                &.buffering::after {
                    content: '📶';
                    z-index: 2000;
                }

                @keyframes blink {
                    50% {
                        opacity: 0.0;
                    }
                }

                &.fullscreen {
                    background-color: black;
                }
            }
            #seekbar span {
                text-align: center;
            }
            .inner .iconContainer svg:nth-last-child(1) {
                 display: none;
            }
        }

        .ticket-check ~ .playercontainer {
            visibility: hidden;
        }

    }

    .indicator {
        display: block;
        position: absolute;
        color: rgba(orangered, .3);
        top: 50%;
        left: calc(50% - .5em);
        font-size: 600%;
        z-index: 20000;
        svg {
            display: block;
            width: 1em;
            height: 1em;
        }
    }

    // hide playback speed
    #seekbar ~ svg:nth-of-type(4) {
        display: none;
    }
}