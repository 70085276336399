@import url(//fonts.googleapis.com/css?family=Vibur);
@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');

body {
  background: rgba(17, 17, 34, .2) url('./brickwall.jpg')  no-repeat center center fixed; 
  background-size: cover;
  margin: 0;

}

h1 {
  font: 400 19vh "Vibur";
  color: #fee;
  text-shadow: 0 -40px 100px, 0 0 2px, 0 0 1em #ff4444, 0 0 0.5em #ff4444, 0 0 0.1em #ff4444, 0 10px 3px #000;
}

ul {
    display: block;
    background-color: #131;
    width: 30vw;
    margin: auto;
    padding: 1em 3em 3em 3em;
    border: solid .2em #eee;
    border-radius: .6em;

    color: #eee;
    font-family: 'Permanent Marker', cursive;
    list-style-type: none;
    display: list-item;
    font-size: 2vw;


    li.link {
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }

    .availability {
      &.soon {
        color: orangered;
      }

      &.verysoon {
        color: red;
      }

      &.now {
        color: greenyellow;
      }
    }
}