


@import './home.scss';
@import './performance.scss';
@import './player.scss';

#about {

    color: whitesmoke;
    position: absolute;
    bottom: 1em;
    left: 1em;
    display: block;
    z-index: 200000;
    &[open] {
        left: 10em;
    }
    summary {
        list-style: none;
        padding: .4em;
        font-weight: 300;
        font-family: "Permanent Marker", cursive;
        border: solid thin transparent;
    }
    summary:focus {
        outline: none;
    }
    summary:hover {
        outline: none;
        border-color: whitesmoke;
    }

    &[open] summary {
        background-color: #131;
        border-color: whitesmoke;
        left: -9em;
    }

    summary::-webkit-details-marker {
        display: none;
    };
    summary {
        position: absolute;
        bottom: 1em;
    }
}